"use strict";

/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-var */

var FACEBOOK_POST_LIMIT = 63206;
var FACEBOOK_INBOX_LIMIT = 2000;
var FACEBOOK_LIMIT = 8000;
var INSTAGRAM_LIMIT = 2200;
var TWITTER_INBOX_LIMIT = 10000;
var TWITTER_LIMIT = 280;
var LINKEDIN_LIMIT = 15000;
var GOOGLEMYBUSINESS_LIMIT = 15000;